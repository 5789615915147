import { createSlice } from '@reduxjs/toolkit';

export type OtherUiState = {
  highlightIosInstallInstruction: boolean;
};

export const REDUCER_NAME = 'otherUi';

const thisReducer = createSlice({
  name: REDUCER_NAME,
  initialState: {
    highlightIosInstallInstruction: false
  } as OtherUiState,
  reducers: {
    setIosInstructionHighlight(state, action) {
      state.highlightIosInstallInstruction = action.payload.enable;
    }
  }
});

export const { setIosInstructionHighlight } = thisReducer.actions;
export default thisReducer.reducer;
