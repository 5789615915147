import { WrappedFetch } from '../client-api/wrappedFetch';
import { AgentSessionInfoResult } from '@property-folders/contract';
import { LinkBuilder } from '../util/LinkBuilder';
import { db } from './db';
import { v4 } from 'uuid';

export interface IOfflineApiQueue {
  id: string;
  agentId: number;
  callTs: number;
  status: 'waiting' | 'attempting' | 'error'; // On success this should be removed
  type: 'json',
  url: string
  method: 'POST' | 'PUT' | 'DELETE',
  bodyBeforeStringify?: any
}

type OfflineApiExecutionType = Omit<IOfflineApiQueue, 'agentId'|'id'|'callTs'>;

export async function apiQueueToRequest<T>(queueItem: OfflineApiExecutionType): Promise<T | undefined> {
  // We're assuming that the agentId has already been checked and is fine. Perhaps the query should be done on the agentId, for example, because it isn't done here
  if (queueItem.status === 'attempting') {
    console.warn('This item is already being attempted');
    return;
  }
  switch (queueItem.type) {
    case 'json': {
      return await WrappedFetch.json<T>(queueItem.url,{
        method: queueItem.method || 'POST',
        ...(queueItem.bodyBeforeStringify ? { body: JSON.stringify(queueItem.bodyBeforeStringify) } : {})
      });
    }
    default: {
      console.warn('Unsupported WrappedFetch type', queueItem.type);
      return;
    }
  }
}

export function generateCreatePortalEntry(propertyId: string, newPortalId: string): OfflineApiExecutionType {
  return {
    status: 'waiting',
    bodyBeforeStringify: { newPortalId },
    url: LinkBuilder.restApi(`/portal/purchaser/create/${propertyId}`),
    method: 'POST',
    type: 'json'
  };
}

export class OfflineQueuedApi {
  private static composeOfflineApiEntry(entryData: OfflineApiExecutionType, session: AgentSessionInfoResult): IOfflineApiQueue {
    return {
      ...entryData,
      agentId: session.agentId,
      callTs: Date.now(),
      id: v4()
    };
  }
  static async createPortal(propertyId: string, newPortalId: string, session: AgentSessionInfoResult) {
    const baseEntry = generateCreatePortalEntry(propertyId, newPortalId);
    const record = OfflineQueuedApi.composeOfflineApiEntry(baseEntry, session);
    db.offlineApiCallQueue.add(record);
  }

  static async runOnlineQueue(agentId: number) {
    db.offlineApiCallQueue.where('agentId').equals(agentId).sortBy('callTs').then(async result => {
      for (const entry of result) {
        if (entry.status === 'attempting') continue;
        await db.offlineApiCallQueue.update(entry.id,{ status: 'attempting' });
        try {
          const res = await apiQueueToRequest(entry);

          await db.offlineApiCallQueue.delete(entry.id);

        } catch (error) {

          await db.offlineApiCallQueue.update(entry.id,{ status: 'error' });
          throw error;
        }
      }
    });
  }
}
