const arrayIndexRegEx = /\[\d+]$/; // ending with [number]
const arrayIndexReplaceRegEx = /.*\[(\d)]+$/;

function getPart(obj: Record<string, any>, segmentName: string, segmentIndex?: number | null) {
  return segmentIndex != null ? obj[segmentName][segmentIndex] : obj[segmentName];
}

export function retrieveByKey(obj: Record<string, any>, path: string): any {
  const properties = path.split('.');

  for (let i = 0; i < properties.length; i++) {
    const isLastProperty = i === properties.length - 1;

    const segment = properties[i];
    const isArrayIndex = arrayIndexRegEx.test(segment);
    const part = isArrayIndex ? segment.replace(arrayIndexRegEx, '') : segment;
    const partIndex = isArrayIndex ? Number(segment.replace(arrayIndexReplaceRegEx, '$1')) : null;

    if (isLastProperty) {
      if (isArrayIndex && partIndex) {
        if (!obj[part]) {
          return undefined;
        }

        return obj[part][partIndex];
      } else {
        return obj[part];
      }
    } else {
      obj = getPart(obj, part, partIndex);
    }
  }

}

export function filterObjectByKey(obj: Record<string, any>, paths: string[]): typeof obj {
  const filtered: Record<string, any> = {};
  for (const path of paths) {
    let originalData: any = { ...obj };
    let workingCopy = filtered;
    const pathParts = path.split('.');

    for (let i = 0; i < pathParts.length; i++) {
      const isLastPart = pathParts.length - 1 === i;
      const segment = pathParts[i];
      const isArrayIndex = arrayIndexRegEx.test(segment);
      const part = isArrayIndex ? segment.replace(arrayIndexRegEx, '') : segment;
      const partIndex = isArrayIndex ? Number(segment.replace(arrayIndexReplaceRegEx, '$1')) : null;

      if (!getPart(originalData, part, partIndex)) {
        break;
      }

      if (isArrayIndex && partIndex) {
        if (!workingCopy[part]) {
          workingCopy[part] = [];
        }
        workingCopy[part][partIndex] = getPart(workingCopy, part, partIndex) ?? {};
      } else {
        workingCopy[part] = getPart(workingCopy, part, partIndex) ?? {};
      }

      originalData = getPart(originalData, part, partIndex);

      if (isLastPart) {
        if (isArrayIndex && partIndex) {
          if (!workingCopy[part]) {
            workingCopy[part] = [];
          }

          workingCopy[part][partIndex] = originalData;
        } else {
          workingCopy[part] = originalData;
        }
      } else {
        workingCopy = getPart(workingCopy, part, partIndex);
      }
    }
  }

  return filtered;
}

export function deepEditObjectInPlace(obj: Record<string, any>, path: string, edit: any) {
  const properties = path.split('.');

  for (let i = 0; i < properties.length; i++) {
    const isLastProperty = i === properties.length - 1;

    const segment = properties[i];
    const isArrayIndex = arrayIndexRegEx.test(segment);
    const part = isArrayIndex ? segment.replace(arrayIndexRegEx, '') : segment;
    const partIndex = isArrayIndex ? Number(segment.replace(arrayIndexReplaceRegEx, '$1')) : null;

    if (isLastProperty) {
      if (isArrayIndex && partIndex !== null) {
        if (!obj[part]) {
          obj[part] = [];
        }

        obj[part][partIndex] = edit;
      } else {
        obj[part] = edit;
      }

      return;
    } else {
      obj = getPart(obj, part, partIndex);
    }
  }
}

export enum ChangeStatus {
  CREATED = 'CREATE',
  UPDATED = 'CHANGE',
  DELETED = 'REMOVE',
  UNCHANGED = 'UNCHANGED'
}

export type DiffRecord = {
  type: ChangeStatus;
  data: DiffRecord | string | number | boolean | null | undefined
};

export type Diff = Record<string, DiffRecord> | DiffRecord;

export type ChangeSet = {key: string; from: any; to: any; type: ChangeStatus}[];

// https://stackoverflow.com/a/8596559
// modified to use native class and add changeset
export class ObjectDiff {
  private changeSet: ChangeSet | null = null;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {
  }

  protected compare(
    obj1: Record<string, any> | undefined,
    obj2: Record<string, any>,
    changeSet: ChangeSet = [],
    currentKey = ''
  ) {
    if (this.changeSet == null) {
      this.changeSet = changeSet;
    }

    if (currentKey.startsWith('.')) {
      currentKey = currentKey.substring(1);
    }

    if (this.isFunction(obj1) || this.isFunction(obj2)) {
      throw 'Invalid argument. Function given, object expected.';
    }

    if (this.isValue(obj1) || this.isValue(obj2)) {
      const changeType = this.compareValues(obj1, obj2);

      this.changeSet.push({
        key: currentKey,
        type: changeType,
        from: obj1,
        to: obj2
      });

      return {
        type: changeType,
        data: obj2,
        changeSet: this.changeSet
      };
    }

    const diff: Record<string, any> = {};
    for (const key in obj1) {
      if (this.isFunction(obj1[key])) {
        continue;
      }

      let value2 = undefined;
      if (obj2[key] !== undefined) {
        value2 = obj2[key];
      }

      const newKey = Array.isArray(obj1) ? `${currentKey}[${key}]` : `${currentKey}.${key}`;
      diff[key] = this.compare(obj1[key], value2, changeSet, newKey);
    }

    for (const key in obj2) {
      if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
        continue;
      }

      const newKey = Array.isArray(obj2) ? `${currentKey}[${key}]` : `${currentKey}.${key}`;
      diff[key] = this.compare(undefined, obj2[key], changeSet, newKey);
    }

    return {
      diff,
      changeSet: this.changeSet
    };
  }

  static map(obj1: Record<string, any> | undefined, obj2: Record<string, any>) {
    return new ObjectDiff().compare(obj1, obj2).diff;
  }

  static getChangeset(obj1: Record<string, any> | undefined, obj2: Record<string, any>) {
    return new ObjectDiff().compare(obj1, obj2).changeSet;
  }

  compareValues(value1: unknown, value2: unknown) {
    if (value1 === value2) {
      return ChangeStatus.UNCHANGED;
    }

    if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
      return ChangeStatus.UNCHANGED;
    }

    if (value1 === undefined) {
      return ChangeStatus.CREATED;
    }

    if (value2 === undefined) {
      return ChangeStatus.DELETED;
    }

    return ChangeStatus.UPDATED;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private isFunction(x: unknown): x is Function {
    return Object.prototype.toString.call(x) === '[object Function]';
  }

  private isArray(x: unknown): x is any[] {
    return Object.prototype.toString.call(x) === '[object Array]';
  }

  private isDate(x: unknown): x is Date {
    return Object.prototype.toString.call(x) === '[object Date]';
  }

  private isObject(x: unknown): x is object {
    return Object.prototype.toString.call(x) === '[object Object]';
  }

  private isValue(x: unknown): x is string | boolean | number | undefined | null {
    return !this.isObject(x) && !this.isArray(x);
  }
}

export function pathToParts(path: string) {
  return path.split('.')
    .map(p => p.split('[').map(k => k.endsWith(']') ? Number(k.slice(0,-1)) : k))
    .flat();
}
