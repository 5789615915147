import { WrappedFetch } from './wrappedFetch';
import { LinkBuilder } from '../util/LinkBuilder';
import {
  ContentType, CreatePurchaserPortalResult,
  GetPurchaserPortalRegistrationResult,
  GetPurchaserPortalResult, PostPropertyPurchaserPortalUserBody,
  PostPurchaserPortalSettingsBody
} from '@property-folders/contract';
import { apiQueueToRequest, generateCreatePortalEntry } from '../offline/pending-api-queue';

export class PurchaserApi {
  // Remember to use online only, there's an offline version that queues
  static async createPortal(propertyId: string, newPortalId: string) {
    const portal = await apiQueueToRequest<CreatePurchaserPortalResult>(generateCreatePortalEntry(propertyId, newPortalId));
    console.log('Create Portal portalId:', portal?.portalId);
  }

  static async getPortal(portalId: string) {
    return await WrappedFetch.json<GetPurchaserPortalResult>(LinkBuilder.restApi(`/portal/purchaser/${portalId}`));
  }

  static async updateSettings(portalId: string, settings: PostPurchaserPortalSettingsBody) {
    return await WrappedFetch.json<PostPurchaserPortalSettingsBody>(LinkBuilder.restApi(`/portal/purchaser/${portalId}/settings`), {
      method: 'POST',
      body: JSON.stringify({ ...settings })
    });
  }

  static async getRegistration(portalId: string) {
    return await WrappedFetch.json<GetPurchaserPortalRegistrationResult>(LinkBuilder.restApi(`/portal/purchaser/${portalId}/registration`));
  }

  static async markPortalUserPrefilled(portalId: string, userId: string) {
    return await WrappedFetch.bare(LinkBuilder.restApi(`/portal/purchaser/${portalId}/mark-prefilled/${userId}`), {
      method: 'POST'
    });
  }

  static async sendInvitation(portalId: string, userId: string, subject: string, message?: string) {
    return await WrappedFetch.bare(LinkBuilder.restApi(`/portal/purchaser/${portalId}/invitation`), {
      method: 'POST',
      body: JSON.stringify({ userId, subject, message })
    });
  }

  static async createPurchaserPortalUser({
    propertyId,
    portalId,
    body
  }: {
    propertyId: string,
    portalId: string,
    body: PostPropertyPurchaserPortalUserBody
  }) {
    return await WrappedFetch.bare(LinkBuilder.restApi(`/properties/${propertyId}/portal/purchaser/${portalId}/users`), {
      method: 'POST',
      headers: new Headers({ 'content-type': ContentType.Json }),
      body: JSON.stringify(body)
    });
  }

  static async deleteInvitedPortalUser({
    propertyId,
    portalId,
    portalUserId
  }: {
    propertyId: string,
    portalId: string,
    portalUserId: string
  }) {
    if (!propertyId || !portalId || !portalUserId) {console.error('Delete called without all require params'); return;}
    return await WrappedFetch.bare(LinkBuilder.restApi(`/properties/${propertyId}/portal/purchaser/${portalId}/users/${portalUserId}/invitation`), {
      method: 'DELETE',
      headers: new Headers({ 'content-type': ContentType.Json })
    });
  }

  static async resendPortalWelcome({
    propertyId,
    portalId,
    portalUserId,
    resendMode
  }: {
    propertyId: string,
    portalId: string,
    portalUserId: string,
    resendMode: 'email' | 'sms'
  }) {
    if (!propertyId || !portalId || !portalUserId) {console.error('Resend called without all require params'); return;}
    return await WrappedFetch.bare(LinkBuilder.restApi(`/properties/${propertyId}/portal/purchaser/${portalId}/users/${portalUserId}/resend-welcome`), {
      method: 'post',
      body: JSON.stringify({ sendMode: resendMode }),
      headers: new Headers({ 'content-type': ContentType.Json })
    });
  }

  static async resendInvitationLink({
    propertyId,
    portalId,
    portalUserId,
    resendMode
  }: {
    propertyId: string,
    portalId: string,
    portalUserId: string,
    resendMode: 'email' | 'sms'
  }) {
    if (!propertyId || !portalId || !portalUserId) {console.error('Resend called without all require params'); return;}
    return await WrappedFetch.bare(LinkBuilder.restApi(`/properties/${propertyId}/portal/purchaser/${portalId}/users/${portalUserId}/resend-invite`), {
      method: 'POST',
      body: JSON.stringify({ sendMode: resendMode }),
      headers: new Headers({ 'content-type': ContentType.Json })
    });
  }

  static async withdrawOffer(propertyId: string, offerId: string, notificationEmail: { subject: string, message?: string } | undefined) {
    return await WrappedFetch.bare(LinkBuilder.restApi(`/properties/${propertyId}/offers/${offerId}/withdraw`), {
      method: 'POST',
      body: JSON.stringify({ ...notificationEmail })
    });
  }

  static async resubmitOffer(propertyId: string, offerId: string, notificationEmail: { subject: string, message?: string } | undefined) {
    return await WrappedFetch.bare(LinkBuilder.restApi(`/properties/${propertyId}/offers/${offerId}/resubmit`), {
      method: 'POST',
      body: JSON.stringify({ ...notificationEmail })
    });
  }

  static async declineOffer(propertyId: string, offerId: string, notificationEmail: { subject: string, message?: string } | undefined) {
    return await WrappedFetch.bare(LinkBuilder.restApi(`/properties/${propertyId}/offers/${offerId}/decline`), {
      method: 'POST',
      body: JSON.stringify({ ...notificationEmail })
    });
  }

  static async acceptOffer(propertyId: string, offerId: string, notificationEmail: { subject: string, message?: string } | undefined) {
    return await WrappedFetch.bare(LinkBuilder.restApi(`/properties/${propertyId}/offers/${offerId}/accept`), {
      method: 'POST',
      body: JSON.stringify({ ...notificationEmail })
    });
  }

}
