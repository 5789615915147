import { PurchaserPortalSettings } from '../purchaser-portal';
import { FileRef, FormCode, FormCodeUnion, SigningPartyType } from '../../property';
import { Maybe } from '@property-folders/common/types/Utility';

/**
 * If you're creating a new Y doc, then load this template into it first.
 */
export const EntitySettingsSchemaTemplate = 'AQKHjcfpAQAnAQZFbnRpdHkSbWFya2V0aW5nVGVtcGxhdGVzACcBBkVudGl0eQtzYWxlc3Blb3BsZQAA';

export enum AgencyVendorMap {
  DEFAULT = 0,
  AGENCY = 1,
  VENDOR = 2
}

export enum EntitySettingsRootKey {
  /**
   * Root key for shape defined by {@link EntitySettingsEntity}
   */
  Main = 'Entity'
}

export enum YesNoNoPreference {
  Yes = 'Yes',
  No = 'No',
  NoPreference = 'NoPreference'
}

export type EntitySettingsSalesperson = {
  id: number;
  name: string;
  phone: string;
  email: string;
  tradeName?: string;
  rla?: string;
  abn?: string;
  isSalesperson: boolean;
  isPropertyManager: boolean;
  isAuctioneer: boolean;
  gnafId: string;
  searchesCreditCents?: number;
  whoPaysTransactionFee?: AgencyVendorMap;
  whoPaysForm1PrepFee?: AgencyVendorMap;
  promoteEC?: YesNoNoPreference;
  form1?: {
    serviceFaxOrEmail?: string;
    serviceAddress?: string;
    serviceAddressIsRla?: boolean;
  }
  isAssocAgent?: boolean
  assocCompanyName?: string
  assocAbn?: string
  assocRla?: string
};

export type EntityBrandButtonConfig = {
  foregroundColour: string;
  backgroundColour: string;
};

export type EntityBrandContentConfig = {
  foregroundColour: string;
  backgroundColour: string;
};

export type EntityBrandFormConfig = {
  lineColour: string;
  headingColour: string;
  agencyContact?: AgencyContact;
};

export type EntityBrandSigningConfig = {
  button: EntityBrandButtonConfig;
  remoteCompletion?: {
    borderColour: string;
  }
};

export type EntityBrandEmailConfig = {
  button: EntityBrandButtonConfig;
  content: EntityBrandContentConfig;
};

export type EntityBrandPurchaserRegistrationConfig = {
  page: EntityBrandContentConfig;
  stripe: EntityBrandContentConfig;
  headerImageS3Uri: string;
};

export type EntityBrandPortalConfig = {
  highlightColour: string;
};

export type AgencyContact = {
  agencyEmail: string,
  agencyName: string,
  agencyPhone: string,
  agencyRla: string
};

export type EntitySettingsBrand = {
  /**
   * @deprecated use email.button
   */
  button: EntityBrandButtonConfig;
  /**
   * @deprecated use email.content
   */
  content: EntityBrandContentConfig;
  form?: EntityBrandFormConfig;
  email?: EntityBrandEmailConfig;
  signing?: EntityBrandSigningConfig;
  purchaserRegistration?: EntityBrandPurchaserRegistrationConfig;
  portal?: EntityBrandPortalConfig;
  agencyContact?: AgencyContact;
};

export type EntitySettingsSigningOptions = {
  allowPaper?: boolean;
  remindersEnable?: boolean;
  firstReminderDay?: number;
  subsequentReminderDays?: number;
  expiryDays?: number;
  expiryReminderDays?: number;
  requireIdRemote?: boolean;
  requireIdHosted?: boolean;
  defaultIdRemote?: boolean;
  defaultIdHosted?: boolean;
  autoServeForm1?: boolean; // It is worth noting, this doesn't affect the users ability to automatically serve Form 1s. But should it?
  formSpecific?: Record<FormCode, Maybe<GreatformsSigningDefaults>>;
};

export enum EntitySettingsRuleTriggerType {
  /**
   * A form event occurred (e.g. 'created', 'served', 'executed' ?).
   * Should provide property data as context to conditions/actions.
   */
  FormStatusChanged = 'form'
}

export enum EntitySettingsRuleConditionType {
  Or = 'or',
  And = 'and',
  /**
   * A system-provided function
   */
  Fn = 'fn',
  /**
   * Required when the trigger type is form
   */
  FormCode = 'formCode',
  /**
   * Required when the trigger type is form
   */
  FormStatus = 'formStatus'
}

export type EntitySettingsRuleConditionLeafType =
  | { type: EntitySettingsRuleConditionType.Fn, name: string }
  | { type: EntitySettingsRuleConditionType.FormCode, formCodes: FormCodeUnion[] }
  | { type: EntitySettingsRuleConditionType.FormStatus, statuses: string[]};
export type EntitySettingsRuleConditionJoinType =
  | { type: EntitySettingsRuleConditionType.Or, conditions: EntitySettingsRuleCondition[] }
  | { type: EntitySettingsRuleConditionType.And, conditions: EntitySettingsRuleCondition[] };
export type EntitySettingsRuleCondition =
  | EntitySettingsRuleConditionJoinType
  | EntitySettingsRuleConditionLeafType;

export enum EntitySettingsRuleActionType {
  /**
   * Send an email
   */
  Email = 'email'
}

export type EntitySettingsRuleAction =
  | { type: EntitySettingsRuleActionType.Email, to?: any[], subject?: string, button?: { text: string, target: string, }, body?: string };

export type EntitySettingsRule = {
  id: string,
  name: string,
  enabled?: boolean,
  /**
   * What triggers this rule to be evaluated?
   */
  trigger?: EntitySettingsRuleTriggerType,
  /**
   * List of conditions that all must be met to process the rule
   */
  conditions?: EntitySettingsRuleCondition[],
  /**
   * What will happen?
   */
  actions?: EntitySettingsRuleAction[]
};

export type DisclosureOfBenefitsTemplateItem = {
  id: string;
  nature?: string;
  source?: string;
  amount?: string;
  recipient?: string;
};

export type DisclosureOfBenefitsTemplate = {
  id: string;
  name: string;
  modified: number;
  items: DisclosureOfBenefitsTemplateItem[]
};

export type MarketingTemplate = {
  id: string;
  //old reaforms documentId from migration
  documentId?: number;
  entityUuid: string;
  modified: number;
  name: string;
  headerImage?: FileRef;
  headerImageScale?: ImageScaleType;
  headerText?: string;
  backgroundColour?: string;
  textColour?: string;
  sectionBackgroundColour: string;
  sectionTextColour: string;
  sectionLineColour: string;
  sections: MarketingTemplateSection[]
};

export type MarketingTemplateSection = {
  id: string;
  defaultState: MarketingTemplateItemState;
  enabled?: boolean;
  locked?: boolean;
  type: MarketingTemplateSectionType;
  name: string;
  backgroundColour?: string;
  textColour?: string;
  lineColour?: string;
  price?: number;
  items?: MarketingTemplateItem[]
};

export enum MarketingTemplateSectionType {
  individual = 'individual',
  package = 'package'
}

export enum MarketingTemplateItemState {
  unchecked = 'unchecked',
  checked = 'checked',
  required = 'required'
}

export enum ImageScaleType {
  none = 'none',
  fit = 'fit',
  stretch = 'stretch'
}

export type MarketingTemplateItem = {
  id: string;
  defaultState: MarketingTemplateItemState;
  enabled: boolean;
  locked: boolean;
  description: string;
  price: number;
};

export type EntitySettingsEntity = {
  entityId: number;
  name: string;
  tradeName: string;
  address1: string;
  address2: string;
  addressDisplay: string;
  suburb: string;
  state: string;
  postcode: string;
  rla: string;
  abn: string;
  phone: string;
  mobile: string;
  email: string;
  profileName: string;
  fax: string;
  logoToShow: number;
  logoS3Uri: string;
  logoMd5Hash: string;
  logoX?: number;
  logoY?: number;
  lastLogoUpdateInSession?: number;
  brand?: EntitySettingsBrand;
  useNewPropertyTransactions: boolean;
  usePfRemoteCompletion?: boolean;
  teamManagementVisible?: boolean;
  salespeople: EntitySettingsSalesperson[] | null;
  gnafId?: string;
  signingOptions: EntitySettingsSigningOptions;
  purchaserPortalSettings: PurchaserPortalSettings;
  enableRealtyAssist?: boolean;
  reaformsTransactionFeeCents?: number;
  searchesCreditCents?: number;
  whoPaysTransactionFee?: AgencyVendorMap;
  whoPaysForm1PrepFee?: AgencyVendorMap;
  waiveTransactionFee?: boolean;
  promoteEC?: boolean;
  form1?: {
    company?: string;
    address?: string;
    serviceFaxOrEmail?: string;
    serviceAddress?: string;
    serviceAddressIsRla?: boolean;
  }
  showLegalLandDescriptionOverride?: boolean;
  /**
   * @deprecated
   */
  marketingTemplateDisableFirstRow?: boolean;
  archived?: boolean;
  epfAgencyId?: number;
  /**
   * note: spec not fully thought out yet.
   * don't develop against this yet.
   */
  rules?: EntitySettingsRule[];
  dobTemplates?: DisclosureOfBenefitsTemplate[];
  dobDefaultTemplateId?: string;
  requireComparableSales?: boolean;
  marketingTemplates?: MarketingTemplate[];
  integrationEnabled?: boolean;
};

export type GreatformsSigningDefaults = {
  enable?: SigningPartyType[];
  disable?: SigningPartyType[];
  default?: SigningPartyType;
  automaticDistribution?: boolean;
};
